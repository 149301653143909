import { createSelector } from "@reduxjs/toolkit";

import { getDeploymentSettingsRequest } from "Api/organizations/projects/getDeploymentSetting.request";
import { updateDeploymentSettingsRequest } from "Api/organizations/projects/updateDeploymentSetting.request";
import { getServiceIcon } from "Components/ServiceIcon/helper";
import { DIALOG_RESOURCE_PROFILE } from "Constants/constants";
import { isDifferent } from "Libs/difference";
import logger from "Libs/logger";
import { setDeep } from "Libs/objectAccess";
import { objectEntries, getProjectId, isJson, roundedNumber } from "Libs/utils";
import { environmentSelector } from "Reducers/environment";
import {
  projectStateSelector,
  gitProjectSelector
} from "Reducers/project/project";
import { projectSettingsSizingApiSelector } from "Reducers/projectSettings";
import { getCommonError } from "Reducers/sliceFactory";
import {
  getDeploymentWebApps,
  getDeploymentServices
} from "src/organization/common/containers/ServicesDisplay/util/serviceGraph";
import { AppDispatch, GetState, RootState } from "Store/configureStore";

import { addClass, addClassWorker } from "./util";

import type {
  LoadDeploymentCurrentProps,
  LoadDeploymentNextProps,
  DeploymentState,
  DeploymentAction,
  ServiceDecodedParams,
  AppAndServiceProps,
  TotalResources,
  ResourceCollectionType,
  OneDeploymentResourceType
} from "./types";
import type {
  Deployment,
  DeploymentService,
  DeploymentUpdateParams
} from "@packages/client";

export * from "./types";

export const NEXT_DEPLOYMENT_ID = "next";
export const CURRENT_DEPLOYMENT_ID = "current";

export const LOAD_DEPLOYMENT_CURRENT_START =
  "app/environmentDeployment/current/load_start";
export const LOAD_DEPLOYMENT_CURRENT_SUCCESS =
  "app/environmentDeployment/current/load_success";
export const LOAD_DEPLOYMENT_CURRENT_FAILURE =
  "app/environmentDeployment/current/load_failure";

export const LOAD_DEPLOYMENT_NEXT_START =
  "app/environmentDeployment/next/load_start";
export const LOAD_DEPLOYMENT_NEXT_SUCCESS =
  "app/environmentDeployment/next/load_success";
export const LOAD_DEPLOYMENT_NEXT_FAILURE =
  "app/environmentDeployment/next/load_failure";

export const UPDATE_DEPLOYMENT_START = "app/environmentDeployment/update_start";
export const UPDATE_DEPLOYMENT_SUCCESS =
  "app/environmentDeployment/update_success";
export const UPDATE_DEPLOYMENT_FAILURE =
  "app/environmentDeployment/update_failure";

export const LOAD_DEPLOYMENT_SETTINGS_START =
  "app/environmentDeploymentSettings/load_start";
export const LOAD_DEPLOYMENT_SETTINGS_SUCCESS =
  "app/environmentDeploymentSettings/load_success";
export const LOAD_DEPLOYMENT_SETTINGS_FAILURE =
  "app/environmentDeploymentSettings/load_failure";

export const UPDATE_DEPLOYMENT_SETTINGS_START =
  "app/environmentDeploymentSettings/update_start";
export const UPDATE_DEPLOYMENT_SETTINGS_SUCCESS =
  "app/environmentDeploymentSettings/update_success";
export const UPDATE_DEPLOYMENT_SETTINGS_FAILURE =
  "app/environmentDeploymentSettings/update_failure";

export const deploymentSelector = (state: RootState) => state.deployment;

export const deploymentVariablesSelector = createSelector(
  deploymentSelector,
  projectStateSelector,
  (
    _: RootState,
    organizationId: string,
    projectId: string,
    environmentId?: string
  ) => ({
    organizationId,
    projectId,
    environmentId
  }),
  (
    deploymentState,
    projectState,
    { organizationId, projectId, environmentId }
  ) => {
    if (!environmentId) {
      environmentId =
        projectState?.data?.[organizationId]?.[projectId]?.default_branch;
    }

    if (!environmentId) {
      return;
    }

    return deploymentState.data?.[organizationId]?.[projectId]?.[environmentId]
      ?.current?.variables;
  }
);

export const generalDeploymentErrorSelector = createSelector(
  deploymentSelector,
  (
    _: RootState,
    params: { organizationId: string; projectId: string; environmentId: string }
  ) => params,
  (deployment, { organizationId, projectId, environmentId }) =>
    deployment?.errors?.[organizationId]?.[projectId]?.[environmentId]
);

export const currentDeploymentErrorSelector = createSelector(
  generalDeploymentErrorSelector,
  environmentObject => environmentObject?.current
);

export const nextDeploymentErrorSelector = createSelector(
  generalDeploymentErrorSelector,
  environmentObject => environmentObject?.next
);
export const deploymentErrorSelector = createSelector(
  currentDeploymentErrorSelector,
  nextDeploymentErrorSelector,
  (currentError, nextError) =>
    (currentError?.message
      ? currentError
      : nextError?.message
        ? nextError
        : undefined) as { message: string; code?: number } | undefined
);

const BLACKFIRE_ID_VARIABLE = "env:BLACKFIRE_SERVER_ID";

export const blackfireEnabledInEnvironmentSelector = createSelector(
  deploymentVariablesSelector,
  variables =>
    variables?.some(variable => variable.name === BLACKFIRE_ID_VARIABLE)
);

export const blackfireUUIDSelector = createSelector(
  deploymentVariablesSelector,
  variables =>
    variables?.find(variable => variable.name === BLACKFIRE_ID_VARIABLE)?.value
);

export const isLoadingSelector = createSelector(
  deploymentSelector,
  deployment => deployment.loading
);

export const isUpdateLoadingSelector = createSelector(
  deploymentSelector,
  deployment => deployment.updateLoading
);

export const allEnvironmentsResource = createSelector(
  deploymentSelector,
  (_: RootState, params: { organizationId: string; projectId: string }) =>
    params,
  (deployment, { organizationId, projectId }) => {
    const environmentLevel = deployment.data?.[organizationId]?.[projectId];
    const resources = Object.values(environmentLevel ?? {}).map(
      deploymentObj => deploymentObj?.[CURRENT_DEPLOYMENT_ID]
    );

    return resources?.filter(resource => typeof resource !== "undefined");
  }
);
export const loadDeploymentSettings = ({
  projectId,
  environmentId,
  organizationId
}) => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: LOAD_DEPLOYMENT_SETTINGS_START,
      payload: {
        projectId,
        environmentId,
        organizationId
      }
    });
    try {
      const deploymentSettings = await getDeploymentSettingsRequest({
        projectId,
        environmentId
      });

      dispatch({
        type: LOAD_DEPLOYMENT_SETTINGS_SUCCESS,
        payload: deploymentSettings,
        meta: { environmentId, projectId, organizationId }
      });
      return LOAD_DEPLOYMENT_SETTINGS_SUCCESS;
    } catch (error) {
      const errorMessage = isJson(error)
        ? error
        : "An error occurred while attempting to load deployment settings.";
      logger(errorMessage, {
        action: "load_deployment_settings"
      });

      dispatch({
        type: LOAD_DEPLOYMENT_SETTINGS_FAILURE,
        error: true,
        payload: {
          error,
          projectId,
          environmentId,
          organizationId
        }
      });
    }
  };
};

export const loadDeploymentCurrent = ({
  organizationId,
  projectId,
  environmentId,
  settings = {}
}: LoadDeploymentCurrentProps) => {
  return async (dispatch: AppDispatch, getState: GetState) => {
    const { escapeLoading } = settings;
    const state = getState();

    const environment = environmentSelector(state, {
      organizationId,
      projectId,
      environmentId
    });

    const hasNecessaryProps = !!(organizationId && projectId && environmentId);

    if (!environment?.has_deployment || !hasNecessaryProps) {
      return;
    }

    const loadingCurrentDeployment = loadingCurrentDeploymentSelector(state);

    if (!escapeLoading && loadingCurrentDeployment) {
      return;
    }

    dispatch({ type: LOAD_DEPLOYMENT_CURRENT_START });

    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      const encodedEnvId = encodeURIComponent(environmentId);

      const projectDescriptionId = getProjectId(getState, projectId)!;
      const deployment = await client.getCurrentDeployment(
        projectDescriptionId,
        encodedEnvId,
        {
          verbose: true
        }
      );

      const services = {
        routes: deployment.routes,
        ...addClass(deployment.services, "service"),
        ...addClass(deployment.webapps, "app"),
        workers: addClassWorker(Object.entries(deployment.workers), "worker")
      };

      const schemaProperties =
        deployment?._links?.self?.meta?.get?.responses?.default?.content?.[
          "application/json"
        ]?.schema?.properties;

      dispatch({
        type: LOAD_DEPLOYMENT_CURRENT_SUCCESS,
        payload: deployment,
        meta: {
          organizationDescriptionId: organizationId,
          environmentDescriptionId: environmentId,
          projectDescriptionId: projectId,
          schemas: schemaProperties,
          services
        }
      });
    } catch (error) {
      if (![404, 403].includes((error as { code: number }).code)) {
        const commonError = getCommonError(error).error;
        const errorMessage =
          commonError?.message ??
          "An error occurred while attempting to load deployment.";

        logger(errorMessage, {
          action: "load_deployment_current",
          meta: {
            organizationDescriptionId: organizationId,
            environmentDescriptionId: environmentId,
            projectDescriptionId: projectId
          }
        });
      }
      dispatch({
        type: LOAD_DEPLOYMENT_CURRENT_FAILURE,
        error: true,
        payload: {
          error,
          organizationDescriptionId: organizationId,
          environmentDescriptionId: environmentId,
          projectDescriptionId: projectId
        }
      });
    }
  };
};

export const loadDeploymentNext = ({
  organizationId,
  projectId,
  environmentId
}: LoadDeploymentNextProps) => {
  return async (dispatch: AppDispatch, getState: GetState) => {
    const state = getState();
    const isSizingApiEnabled = deploymentSizingApiEnabledSelector(state, {
      organizationId,
      projectId,
      environmentId
    });

    const environment = environmentSelector(state, {
      organizationId,
      projectId,
      environmentId
    });

    const hasNecessaryProps = !!(organizationId && projectId && environmentId);
    const loadingNextDeployment = loadingNextDeploymentSelector(state);

    if (
      !isSizingApiEnabled ||
      !environment?.has_deployment ||
      !hasNecessaryProps ||
      loadingNextDeployment
    ) {
      return;
    }

    dispatch({ type: LOAD_DEPLOYMENT_NEXT_START });

    try {
      const platformLib = await import("Libs/platform");
      const client = platformLib.default;
      const encodedEnvId = encodeURIComponent(environmentId);

      const projectDescriptionId = getProjectId(getState, projectId)!;
      const deployment = await client.getNextDeployment(
        projectDescriptionId,
        encodedEnvId,
        {
          verbose: true
        }
      );

      const schemaProperties =
        deployment?._links?.self?.meta?.get?.responses?.default?.content?.[
          "application/json"
        ]?.schema?.properties;

      dispatch({
        type: LOAD_DEPLOYMENT_NEXT_SUCCESS,
        payload: deployment,
        meta: {
          organizationDescriptionId: organizationId,
          environmentDescriptionId: environmentId,
          projectDescriptionId: projectId,
          schemas: schemaProperties
        }
      });
    } catch (error) {
      if (![404, 403].includes((error as { code: number }).code)) {
        const commonError = getCommonError(error).error;
        const errorMessage =
          commonError?.message ??
          "An error occurred while attempting to load deployment.";

        logger(errorMessage, {
          action: "load_deployment_next",
          meta: {
            organizationDescriptionId: organizationId,
            environmentDescriptionId: environmentId,
            projectDescriptionId: projectId
          }
        });
      }
      dispatch({
        type: LOAD_DEPLOYMENT_NEXT_FAILURE,
        error: true,
        payload: {
          error,
          organizationDescriptionId: organizationId,
          environmentDescriptionId: environmentId,
          projectDescriptionId: projectId
        }
      });
    }
  };
};

export const updateDeployment = (
  deployment: Deployment,
  data: DeploymentUpdateParams,
  organizationDescriptionId: string,
  projectDescriptionId: string,
  environmentDescriptionId: string
) => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: UPDATE_DEPLOYMENT_START,
      payload: {
        organizationDescriptionId,
        projectDescriptionId,
        environmentDescriptionId
      }
    });
    try {
      const result = await deployment.update(data);

      const updateDeployment: Deployment = result.getEntity();
      dispatch({
        type: UPDATE_DEPLOYMENT_SUCCESS,
        payload: updateDeployment,
        meta: {
          organizationDescriptionId,
          environmentDescriptionId,
          projectDescriptionId,
          deploymentId: NEXT_DEPLOYMENT_ID
        }
      });
      return UPDATE_DEPLOYMENT_SUCCESS;
    } catch (error) {
      dispatch({
        type: UPDATE_DEPLOYMENT_FAILURE,
        payload: {
          error,
          organizationDescriptionId,
          environmentDescriptionId,
          projectDescriptionId
        }
      });
    }
  };
};
export const updateDeploymentSettings = ({
  projectId,
  environmentId,
  organizationId,
  explicitDeploymentsEnabled
}) => {
  return async (dispatch: AppDispatch) => {
    dispatch({
      type: UPDATE_DEPLOYMENT_SETTINGS_START,
      payload: {
        projectId,
        environmentId,
        explicitDeploymentsEnabled
      }
    });
    try {
      const updatedDeploymentSettings = await updateDeploymentSettingsRequest({
        projectId,
        environmentId,
        explicitDeploymentsEnabled
      });

      dispatch({
        type: UPDATE_DEPLOYMENT_SETTINGS_SUCCESS,
        payload: updatedDeploymentSettings?._embedded?.entity,
        meta: { environmentId, projectId, organizationId }
      });
      return UPDATE_DEPLOYMENT_SETTINGS_SUCCESS;
    } catch (error) {
      const errorMessage = isJson(error)
        ? error
        : "An error occurred while attempting to update deployment settings.";
      logger(errorMessage, {
        action: "update_deployment_settings"
      });

      dispatch({
        type: UPDATE_DEPLOYMENT_SETTINGS_FAILURE,
        error: true,
        payload: {
          error,
          projectId,
          environmentId,
          organizationId,
          explicitDeploymentsEnabled
        }
      });
    }
  };
};

export const selectDeployment = (
  state: RootState,
  {
    organizationId,
    projectId,
    environmentId,
    deploymentId
  }: {
    organizationId?: string;
    projectId?: string;
    environmentId?: string;
    deploymentId: string;
  }
) => {
  const environment =
    environmentId ||
    (typeof projectId === "undefined"
      ? undefined
      : gitProjectSelector(state, { organizationId, projectId })
          ?.default_branch);

  if (
    typeof state.deployment.data !== "undefined" &&
    organizationId &&
    projectId
  ) {
    return state.deployment?.data[organizationId]?.[projectId]?.[
      environment || "master"
    ]?.[deploymentId];
  }
};

export const currentDeployment = (
  state: RootState,
  {
    organizationId,
    projectId,
    environmentId
  }: { organizationId?: string; projectId?: string; environmentId?: string }
) => {
  return selectDeployment(state, {
    organizationId,
    projectId,
    environmentId,
    deploymentId: CURRENT_DEPLOYMENT_ID
  });
};

export const selectDeploymentSettings = (
  state: RootState,
  {
    projectId,
    environmentId,
    organizationId
  }: {
    projectId: string;
    environmentId: string;
    organizationId: string;
  }
) => {
  if (typeof state.deployment.settings !== "undefined") {
    return state.deployment.settings?.[organizationId]?.[projectId]?.[
      environmentId
    ];
  }
};

export const isExplicitDeploymentEnabledSelector = createSelector(
  selectDeploymentSettings,
  state => state?.explicit_deployments_enabled
);

export const deploymentServicesSelector = createSelector(
  deploymentSelector,
  deployment => deployment.services
);

export const currentDeploymentServicesSelector = createSelector(
  deploymentServicesSelector,
  (
    _: RootState,
    params: {
      projectId: string;
      environmentId: string;
      organizationId: string;
    }
  ) => params,
  (currentDeploymentServices, { organizationId, projectId, environmentId }) =>
    currentDeploymentServices?.[organizationId]?.[projectId]?.[environmentId]
);

export const selectCurrentService = createSelector(
  currentDeploymentServicesSelector,
  (_: RootState, params: ServiceDecodedParams) => params,
  (
    currentDeploymentData,
    { appName }
  ): { class: string; icon?: string; name: string } | undefined =>
    currentDeploymentData?.[appName]
);

export const selectCurrentServiceWorkers = createSelector(
  currentDeploymentServicesSelector,
  (_: RootState, params: ServiceDecodedParams) => params,

  (currentDeploymentData, { appName }) => {
    const workers = currentDeploymentData?.["workers"] || [];

    const serviceWorkers = Object.values(workers).filter(worker =>
      worker?.name.startsWith(`${appName}--`)
    );

    return serviceWorkers;
  }
);

export const selectCurrentServiceCronJobs = createSelector(
  selectCurrentService,
  appNameService => {
    const crons = appNameService?.["crons"];

    const cronsEntries = Object.entries(crons);
    if (!cronsEntries.length) return [];

    const serviceCronJobs = cronsEntries.map(([key, value]) => ({
      ...(value as object),
      name: key
    }));

    return serviceCronJobs;
  }
);

export const currentAppAndServiceSelector = createSelector(
  currentDeployment,
  (_: RootState, params: AppAndServiceProps) => params,
  (currentDeploymentData, { appName, key }) =>
    appName ? currentDeploymentData?.[key]?.[appName] : undefined
);

export const currentAppSelector = createSelector(
  currentAppAndServiceSelector,
  (_: RootState, params: AppAndServiceProps) => params,
  (app, { appName, key }) => {
    if (key === "webapps" && appName) {
      const { iconName } = getServiceIcon(app as DeploymentService);

      const a = getDeploymentWebApps({
        name: appName,
        iconName,
        currentLine: 0,
        column: 0,
        app: app as DeploymentService
      });

      return a;
    }
  }
);

export const currentServiceSelector = createSelector(
  currentAppAndServiceSelector,
  (_: RootState, params: AppAndServiceProps) => params,
  (service, { appName: serviceName, key }) => {
    if (key === "services" && serviceName) {
      const type = service?.type?.split(":")?.[1];

      const s = getDeploymentServices({
        name: serviceName,
        type,
        currentLine: 0,
        column: 0,
        service: service as DeploymentService
      });

      return s;
    }
  }
);

export const nextDeployment = (
  state: RootState,
  {
    organizationId,
    projectId,
    environmentId
  }: { organizationId: string; projectId?: string; environmentId?: string }
) => {
  return selectDeployment(state, {
    organizationId,
    projectId,
    environmentId,
    deploymentId: NEXT_DEPLOYMENT_ID
  });
};

export const deploymentSelectors = {
  currentDeployment,
  nextDeployment
};

export const currentDeploymentClassSelector = (
  state: RootState,
  {
    organizationId,
    projectId,
    environmentId
  }: { organizationId: string; projectId?: string; environmentId?: string }
) => {
  return currentDeployment(state, {
    organizationId,
    projectId,
    environmentId
  });
};

export const resourcesAccumulatorHandler = (
  totalResources: TotalResources,
  resourceItem: { [K in keyof TotalResources]?: TotalResources[K] | null }
): TotalResources & { status: string } => {
  const cpu = resourceItem?.cpu ?? 0;
  const ram = resourceItem?.ram ?? 0;
  const instances = resourceItem?.instances ?? 1;
  const disk = resourceItem?.disk ?? 0;
  const status = resourceItem?.status ?? "";

  return {
    cpu: totalResources.cpu + cpu * instances,
    ram: totalResources.ram + ram * instances,
    instances: totalResources.instances + instances,
    disk: totalResources.disk + disk,
    status
  };
};

export const resourcesFormatHandler = (
  totalResources: TotalResources
): TotalResources => ({
  cpu: roundedNumber(totalResources.cpu, 3),
  ram: roundedNumber(totalResources.ram / 1024, 3),
  instances: totalResources.instances,
  disk: roundedNumber(totalResources.disk / 1024, 3),
  status: totalResources.status
});

export const oneDeploymentResourceSelector = (
  state: RootState,
  {
    organizationId,
    projectId,
    environmentId,
    resourceType,
    appName
  }: {
    organizationId: string;
    projectId?: string;
    environmentId?: string;
    resourceType: ResourceCollectionType;
    appName: string;
  }
): OneDeploymentResourceType => {
  const currentDeploymentValue = currentDeployment(state, {
    organizationId,
    projectId,
    environmentId
  });
  const currentResource = {
    cpu: 0,
    ram: 0,
    instances: 0,
    disk: 0,
    status: ""
  };
  const allProfiles = currentDeploymentValue?.container_profiles;
  const resource = currentDeploymentValue?.[resourceType];
  const resourceApp = resource?.[appName];
  const cronsQuantity = Object.keys(resourceApp?.crons ?? {}).length;
  const profileSize = resourceApp?.resources?.profile_size;
  const containerProfile = resourceApp?.container_profile;
  const currentProfile = allProfiles?.[containerProfile!];
  const cpu = currentProfile?.[profileSize!].cpu ?? 0;
  const ram = currentProfile?.[profileSize!].memory ?? 0;
  const disk = resourceApp?.disk ?? 0;
  const instances = resourceApp?.instance_count ?? 1;
  const status = currentDeploymentValue?.environment_info?.status ?? "";

  const currentResourceFormatted = resourcesFormatHandler(
    resourcesAccumulatorHandler(currentResource, {
      cpu,
      ram,
      instances,
      disk,
      status
    })
  );

  return {
    ...currentResourceFormatted,
    profileLabel: DIALOG_RESOURCE_PROFILE[containerProfile!],
    crons: cronsQuantity
  };
};

export const deploymentResourcesHandler = (deployment?: Deployment) => {
  let totalResources = {
    cpu: 0,
    ram: 0,
    instances: 0,
    disk: 0,
    status: ""
  };
  const { webapps, workers, services } = deployment ?? {
    webapps: undefined,
    workers: undefined,
    services: undefined
  };
  const status = deployment?.environment_info?.status ?? "";

  try {
    Object.values({ webapps, workers, services }).forEach(resourceCollection =>
      Object.values(resourceCollection ?? {}).forEach(data => {
        const containerProfiles =
          deployment?.container_profiles[data.container_profile ?? ""];

        const cpu = containerProfiles?.[data?.resources.profile_size ?? ""].cpu;
        const ram =
          containerProfiles?.[data?.resources.profile_size ?? ""].memory;
        const { disk, instance_count: instances } = data;

        totalResources = resourcesAccumulatorHandler(totalResources, {
          cpu,
          ram,
          instances,
          disk,
          status
        });
      })
    );

    return resourcesFormatHandler(totalResources);
  } catch {
    return { cpu: 0, ram: 0, instances: 0, disk: 0, status };
  }
};

export const deploymentResourcesSelector = (
  state: RootState,
  {
    organizationId,
    projectId,
    environmentId
  }: { organizationId: string; projectId?: string; environmentId?: string }
) => {
  const current = currentDeployment(state, {
    organizationId,
    projectId,
    environmentId
  });

  return deploymentResourcesHandler(current);
};

export const deploymentSizingApiEnabledSelector = (
  state: RootState,
  {
    organizationId,
    projectId,
    environmentId
  }: { organizationId: string; projectId?: string; environmentId?: string }
) => {
  const deployment =
    currentDeploymentClassSelector(state, {
      organizationId,
      projectId,
      environmentId
    }) ??
    nextDeployment(state, {
      organizationId,
      projectId,
      environmentId
    });

  const deploymentSizingApi = (
    deployment?.project_info as
      | { settings: { sizing_api_enabled: boolean } | undefined }
      | undefined
  )?.settings?.sizing_api_enabled;

  const projectSettingSizingApi = projectSettingsSizingApiSelector(state, {
    projectId: projectId!
  });

  if (typeof deploymentSizingApi === "undefined") {
    return projectSettingSizingApi;
  }
  return deploymentSizingApi;
};

export const deploymentStatusCodesSelector = createSelector(
  deploymentSelector,
  (
    _: RootState,
    params: { organizationId: string; projectId: string; environmentId: string }
  ) => params,
  (deployment, { organizationId, projectId, environmentId }) => {
    const { current, next } = deployment?.responseCode?.[organizationId]?.[
      projectId
    ]?.[environmentId] ?? {
      current: undefined,
      next: undefined
    };

    return { current, next };
  }
);

const deploymentValueChecks = (
  deploymentNext: Deployment | undefined,
  deploymentCurrent: Deployment | undefined
) => {
  const nextValues: Record<string, any> = {};
  const currentValues: Record<string, any> = {};

  const nextDeployment = {
    webapps: deploymentNext?.webapps,
    workers: deploymentNext?.workers,
    services: deploymentNext?.services
  };

  const currentDeployment = {
    webapps: deploymentCurrent?.webapps,
    workers: deploymentCurrent?.workers,
    services: deploymentCurrent?.services
  };

  [nextDeployment, currentDeployment].forEach((deployment, i) => {
    const deploymentGroup = i === 0 ? nextValues : currentValues;
    objectEntries(deployment).forEach(
      ([resourceCollectionType, resourceCollection]) => {
        objectEntries(resourceCollection ?? {}).forEach(([label, data]) => {
          setDeep(
            deploymentGroup,
            [resourceCollectionType, label, "instance_count"],
            data?.instance_count
          );

          setDeep(
            deploymentGroup,
            [resourceCollectionType, label, "resources", "profile_size"],
            data?.resources.profile_size
          );

          setDeep(
            deploymentGroup,
            [resourceCollectionType, label, "disk"],
            data?.disk
          );
        });
      }
    );
  });

  return { nextValues, currentValues };
};

export const resourcesDeploymentStatusSelector = (
  state: RootState,
  {
    organizationId,
    projectId = "",
    environmentId = ""
  }: { organizationId: string; projectId?: string; environmentId?: string }
): "Bad configuration" | "No resources configured" | "Build failed" | "" => {
  const { current: currentCode, next: nextCode } =
    deploymentStatusCodesSelector(state, {
      organizationId,
      projectId,
      environmentId
    });

  const deploymentCurrent = currentDeploymentClassSelector(state, {
    organizationId,
    projectId,
    environmentId
  });
  const deploymentNext = nextDeployment(state, {
    organizationId,
    projectId,
    environmentId
  });

  const { nextValues, currentValues } = deploymentValueChecks(
    deploymentNext,
    deploymentCurrent
  );

  if (nextCode === 400 && currentCode === 404) {
    return "Bad configuration";
  } else if (
    (nextCode === 200 &&
      currentCode === 200 &&
      isDifferent(nextValues, currentValues)) ||
    (nextCode === 200 && currentCode === 404)
  ) {
    return "No resources configured";
  } else if (nextCode?.toString().match(/^[45]/) && currentCode === 200) {
    return "Build failed";
  }
  return "";
};

export const allResourcesHaveProfileSizeSelector = createSelector(
  nextDeployment,
  nextDeployment => {
    if (typeof nextDeployment === "undefined") {
      return;
    }
    let hasProfileSize = true;
    const resources = {
      webapps: nextDeployment?.webapps,
      workers: nextDeployment?.workers,
      services: nextDeployment?.services
    };

    Object.values(resources).forEach(resourceCollection => {
      Object.values(resourceCollection).forEach(data => {
        hasProfileSize = hasProfileSize && !!data?.resources?.profile_size;
      });
    });

    return hasProfileSize;
  }
);

export default function environmentDeploymentReducer(
  state: DeploymentState = {},
  action: DeploymentAction
): DeploymentState {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case UPDATE_DEPLOYMENT_SETTINGS_START: {
      const { organizationId, projectId, environmentId } = action.payload;
      setDeep(
        newState,
        ["errors", organizationId, projectId, environmentId, "settings"],
        undefined
      );
      return { ...newState, loadingSettings: true };
    }
    case UPDATE_DEPLOYMENT_SETTINGS_SUCCESS: {
      const { organizationId, projectId, environmentId } = action.meta;
      setDeep(
        newState,
        ["settings", organizationId, projectId, environmentId],
        action.payload
      );
      return { ...newState, loadingSettings: false };
    }
    case UPDATE_DEPLOYMENT_SETTINGS_FAILURE: {
      const { error, organizationId, projectId, environmentId } =
        action.payload;
      setDeep(
        newState,
        ["errors", organizationId, projectId, environmentId, "settings"],
        error
      );

      return {
        ...newState,
        loading: false,
        loadingSettings: false
      };
    }

    case LOAD_DEPLOYMENT_SETTINGS_START:
      return { ...newState, loading: true, loadingSettings: true };
    case LOAD_DEPLOYMENT_SETTINGS_SUCCESS:
      setDeep(
        newState,
        [
          "settings",
          action.meta.organizationId,
          action.meta.projectId,
          action.meta.environmentId
        ],
        action.payload
      );

      return {
        ...newState,
        loading: false,
        loadingSettings: false
      };
    case LOAD_DEPLOYMENT_SETTINGS_FAILURE: {
      const { error, organizationId, projectId, environmentId } =
        action.payload;
      setDeep(
        newState,
        ["errors", organizationId, projectId, environmentId, "settings"],
        error
      );
      setDeep(
        newState,
        ["responseCode", organizationId, projectId, environmentId, "settings"],
        error.code
      );
      return {
        ...newState,
        loading: false,
        loadingSettings: false
      };
    }

    case LOAD_DEPLOYMENT_CURRENT_START: {
      return { ...newState, loading: true, loadingCurrent: true };
    }
    case LOAD_DEPLOYMENT_CURRENT_SUCCESS: {
      const {
        organizationDescriptionId,
        projectDescriptionId,
        environmentDescriptionId,
        schemas
      } = action.meta;

      setDeep(
        newState,
        [
          "data",
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId,
          CURRENT_DEPLOYMENT_ID
        ],
        action.payload
      );

      setDeep(
        newState,
        [
          "errors",
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId,
          CURRENT_DEPLOYMENT_ID
        ],
        undefined
      );

      setDeep(
        newState,
        [
          "responseCode",
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId,
          CURRENT_DEPLOYMENT_ID
        ],
        200
      );
      setDeep(
        newState,
        [
          "services",
          action.meta.organizationDescriptionId,
          action.meta.projectDescriptionId,
          action.meta.environmentDescriptionId
        ],
        action.meta.services
      );

      return {
        ...newState,
        schemas,
        loading: false,
        loadingCurrent: false
      };
    }
    case LOAD_DEPLOYMENT_CURRENT_FAILURE: {
      const {
        error,
        organizationDescriptionId,
        projectDescriptionId,
        environmentDescriptionId
      } = action.payload;

      setDeep(
        newState,
        [
          "errors",
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId,
          CURRENT_DEPLOYMENT_ID
        ],
        error
      );

      setDeep(
        newState,
        [
          "responseCode",
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId,
          CURRENT_DEPLOYMENT_ID
        ],
        error.code
      );

      return {
        ...newState,
        loading: false,
        loadingCurrent: false
      };
    }

    case LOAD_DEPLOYMENT_NEXT_START: {
      return { ...newState, loading: true, loadingNext: true };
    }
    case LOAD_DEPLOYMENT_NEXT_SUCCESS: {
      const {
        organizationDescriptionId,
        projectDescriptionId,
        environmentDescriptionId,
        schemas
      } = action.meta;

      setDeep(
        newState,
        [
          "data",
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId,
          NEXT_DEPLOYMENT_ID
        ],
        action.payload
      );

      setDeep(
        newState,
        [
          "errors",
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId,
          NEXT_DEPLOYMENT_ID
        ],
        undefined
      );

      setDeep(
        newState,
        [
          "responseCode",
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId,
          NEXT_DEPLOYMENT_ID
        ],
        200
      );

      return {
        ...newState,
        schemas,
        loading: false,
        loadingNext: false
      };
    }
    case LOAD_DEPLOYMENT_NEXT_FAILURE: {
      const {
        error,
        organizationDescriptionId,
        projectDescriptionId,
        environmentDescriptionId
      } = action.payload;

      setDeep(
        newState,
        [
          "errors",
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId,
          NEXT_DEPLOYMENT_ID
        ],
        error
      );

      setDeep(
        newState,
        [
          "responseCode",
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId,
          NEXT_DEPLOYMENT_ID
        ],
        error.code
      );

      return {
        ...newState,
        loading: false,
        loadingNext: false
      };
    }

    case UPDATE_DEPLOYMENT_START: {
      const {
        organizationDescriptionId,
        projectDescriptionId,
        environmentDescriptionId
      } = action.payload;

      setDeep(
        newState,
        [
          "errors",
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId,
          CURRENT_DEPLOYMENT_ID
        ],
        undefined
      );

      setDeep(
        newState,
        [
          "errors",
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId,
          NEXT_DEPLOYMENT_ID
        ],
        undefined
      );
      return {
        ...newState,
        updateLoading: true
      };
    }
    case UPDATE_DEPLOYMENT_SUCCESS:
      setDeep(
        newState,
        [
          "data",
          action.meta.organizationDescriptionId,
          action.meta.projectDescriptionId,
          action.meta.environmentDescriptionId,
          action.meta.deploymentId
        ],
        action.payload
      );

      setDeep(
        newState,
        [
          "responseCode",
          action.meta.organizationDescriptionId,
          action.meta.projectDescriptionId,
          action.meta.environmentDescriptionId,
          action.meta.deploymentId
        ],
        200
      );

      return {
        ...newState,
        updateLoading: false
      };
    case UPDATE_DEPLOYMENT_FAILURE: {
      const {
        error,
        organizationDescriptionId,
        projectDescriptionId,
        environmentDescriptionId
      } = action.payload;

      setDeep(
        newState,
        [
          "errors",
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId,
          NEXT_DEPLOYMENT_ID
        ],
        error
      );

      setDeep(
        newState,
        [
          "responseCode",
          organizationDescriptionId,
          projectDescriptionId,
          environmentDescriptionId,
          NEXT_DEPLOYMENT_ID
        ],
        error.code
      );

      return {
        ...newState,
        updateLoading: false
      };
    }
    default:
      return newState;
  }
}

export const deploymentHasLanguageSelector = (
  state: RootState,
  {
    environmentId,
    organizationId,
    projectId
  }: { environmentId?: string; organizationId?: string; projectId?: string },
  languages: string[] = []
) => {
  const deployment = currentDeployment(state, {
    environmentId,
    organizationId,
    projectId
  });
  return Object.keys(deployment?.webapps || {}).some(appName =>
    languages.some(supportType => {
      const appType = deployment?.webapps[appName].type.toLowerCase();
      return appType?.indexOf(supportType.toLowerCase()) !== -1;
    })
  );
};

export const loadingDeploymentSelector = (state: RootState) =>
  state.deployment.loading;

export const loadingNextDeploymentSelector = (state: RootState) =>
  state.deployment.loadingNext;

export const loadingCurrentDeploymentSelector = (state: RootState) =>
  state.deployment.loadingCurrent;

export const loadingDeploymentSettingsSelector = (state: RootState) =>
  state.deployment.loadingSettings;

export const updateDeploymentSettingsErrorSelector = (
  state: RootState,
  {
    environmentId,
    organizationId,
    projectId
  }: { environmentId: string; organizationId: string; projectId: string }
) =>
  state.deployment.errors?.[organizationId]?.[projectId]?.[environmentId]
    ?.settings;
