import { getServiceIcon } from "Components/ServiceIcon/helper";

import type { AddClassProps, ReturnAddClass } from "./types";
import type { DeploymentService } from "@packages/client";

export const addClass = (
  list: AddClassProps["list"] = {},
  newClass: AddClassProps["newClass"]
) => {
  return Object.keys(list).reduce<ReturnAddClass>((current, key) => {
    const icon = getServiceIcon(list[key]).iconName;
    const name = list[key]?.name || key;

    current[key] = { icon, class: newClass, ...list[key], name };
    return current;
  }, {});
};

export const addClassWorker = (
  list: [string, DeploymentService][] = [],
  newClass: AddClassProps["newClass"]
) => {
  return list.reduce<ReturnAddClass>((acc, [key, value], index) => {
    const icon = getServiceIcon(value).iconName;
    const name = value?.name || key;

    acc[index] = { icon, class: newClass, ...value, name };

    return acc;
  }, {});
};
