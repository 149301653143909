import { apiProxyRequest } from "Api/apiProxyRequest";
import { UpdateDeploymentSettingsResponse } from "Reducers/environment/deployment";

export const updateDeploymentSettingsRequest = ({
  projectId,
  environmentId,
  explicitDeploymentsEnabled
}) =>
  apiProxyRequest<UpdateDeploymentSettingsResponse>(
    `/projects/${projectId}/environments/${environmentId}/settings`,
    "PATCH",
    {
      explicit_deployments_enabled: explicitDeploymentsEnabled
    }
  );
