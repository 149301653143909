import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { getAutoscalingConfiguration } from "Api/organizations/projects/getAutoscalingConfiguration.request";
import { setAutoscalingConfiguration } from "Api/organizations/projects/setAutoscalingConfiguration.request";
import { AsyncThunkOptionType } from "Reducers/types";

import { ErrorResponse } from "./types";

import { ServiceConfig, ServiceState, Settings } from "./";

export const setAutoscalingConfigurationThunk = createAsyncThunk<
  Record<string, ServiceConfig>,
  { projectId: string; environmentId: string; settings: Settings },
  AsyncThunkOptionType
>(
  "app/environment/service/setAutoscalingConfiguration",
  async ({ projectId, environmentId, settings }, { rejectWithValue }) => {
    try {
      const { services } = await setAutoscalingConfiguration(
        projectId,
        environmentId,
        settings
      );

      return services;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const getAutoscalingConfigurationThunk = createAsyncThunk<
  Settings,
  { projectId: string; environmentId: string },
  AsyncThunkOptionType
>(
  "app/environment/service/getAutoscalingConfiguration",
  async ({ projectId, environmentId }, { rejectWithValue }) => {
    try {
      const { services } = await getAutoscalingConfiguration(
        projectId,
        environmentId
      );

      return services;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

const initialState: ServiceState = {
  autoscalingLoading: false,
  autoscalingError: null,
  autoscalingConfiguration: { services: {} }
};

const serviceReducer = createSlice({
  name: "app/environment/service",
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(setAutoscalingConfigurationThunk.pending, state => {
        state.autoscalingLoading = true;
        state.autoscalingError = null;
      })
      .addCase(setAutoscalingConfigurationThunk.fulfilled, (state, action) => {
        state.autoscalingLoading = false;
        state.autoscalingConfiguration = action.payload;
      })
      .addCase(setAutoscalingConfigurationThunk.rejected, (state, action) => {
        state.autoscalingLoading = false;
        state.autoscalingError = action?.payload as ErrorResponse;
      })
      .addCase(getAutoscalingConfigurationThunk.pending, state => {
        state.autoscalingLoading = true;
        state.autoscalingError = null;
      })
      .addCase(getAutoscalingConfigurationThunk.fulfilled, (state, action) => {
        state.autoscalingLoading = false;
        state.autoscalingConfiguration = action.payload;
      })
      .addCase(getAutoscalingConfigurationThunk.rejected, (state, action) => {
        state.autoscalingLoading = false;
        state.autoscalingError = action?.payload as ErrorResponse;
      });
  }
});

export default serviceReducer.reducer;
